import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat2Id} from '@shared/dynamo_model';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizCat3View} from '@src/components/ui/hoobiiz_cat3_view';
import {getCat1Url, getCat3Url} from '@src/lib/hoobiiz_urls';

interface HoobiizCat3ListViewProps {
  cat2Id: HoobiizCat2Id;
}

export const HoobiizCat3ListView: FC<HoobiizCat3ListViewProps> = props => {
  const {cat2Id} = props;
  const {data} = useApiCall(HoobiizApi, '/list-cat3', {cat2Id});

  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <BackNavLink to={data.cat2.cat1 ? getCat1Url(data.cat2.cat1) : '/'}>
        <SvgIcon name="ChevronLeft" size={11} color={Colors.DarkGold} />
        {data.cat2.cat1?.name ?? 'Retour'}
      </BackNavLink>

      <Header>
        {data.cat3.length === 0 ? (
          <HoobiizMediaView radius={16} media={data.cat2.media} width={135} height={100} />
        ) : (
          EmptyFragment
        )}
        <Titles>
          <Title>{data.cat2.name}</Title>
          <Description>{data.cat2.description}</Description>
        </Titles>
      </Header>
      {data.cat3.length === 0 ? (
        EmptyFragment
      ) : (
        <Gradient>
          <Carousel>
            {data.cat3.map(cat => (
              <HoobiizCat3View
                key={cat.id}
                to={getCat3Url(cat)}
                name={cat.name}
                media={cat.media}
              />
            ))}
          </Carousel>
        </Gradient>
      )}
    </Wrapper>
  );
};

HoobiizCat3ListView.displayName = 'HoobiizCat3ListView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Gradient = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(to right, transparent, #ffffff);
    pointer-events: none;
  }
`;

const Carousel = styled.div`
  display: flex;
  height: 100px;
  overflow-x: auto;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.DarkGrey};
`;

const BackNavLink = styled(NavLink)`
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  gap: 16px;
`;
const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
