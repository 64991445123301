import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizUserGroupId, HoobiizWhiteLabeling} from '@shared/dynamo_model';
import {IS_LOCALHOST_ENV} from '@shared/env_constants';

import {apiCall} from '@shared-frontend/api';
import {Button, Link} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';

import {FormSection} from '@src/components/admin/form/form_section';
import {getHoobiizUserGroupModalPath} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {
  getOrFetchHoobiizUserGroup,
  HoobiizUserGroupStoreItem,
  useHoobiizUserGroup,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupThemeLoginConfig} from '@src/components/admin/user_and_group/admin_user_and_group_theme_login';
import {AdminNavLink} from '@src/components/core/theme';

interface AdminUserAndGroupThemePageProps {
  groupId: HoobiizUserGroupId;
  groupIds: HoobiizUserGroupId[];
}

function groupWhiteLabeling(group?: HoobiizUserGroupStoreItem): HoobiizWhiteLabeling | undefined {
  return group ? group.item.groupProfile.whiteLabeling ?? {} : undefined;
}

export const AdminUserAndGroupThemePage: FC<AdminUserAndGroupThemePageProps> = props => {
  // Load and maintain the current WhiteLabeling of the group
  const {groupId, groupIds} = props;
  // const groups = useHoobiizUserGroups({groupIds});
  const group = useHoobiizUserGroup({groupId});
  const [whiteLabeling, setWhiteLabeling] = useState(groupWhiteLabeling(group));
  useEffect(() => setWhiteLabeling(groupWhiteLabeling(group)), [group]);

  // Handle changes
  const handleLoginChange = useCallback((login: HoobiizWhiteLabeling['login']) => {
    setWhiteLabeling(prev => ({...prev, login}));
  }, []);

  const handleSave = useCallback(async () => {
    if (!whiteLabeling) {
      return;
    }
    await apiCall(HoobiizApi, '/admin/update-group-white-labeling', {
      groupId,
      whiteLabeling,
    });
    await getOrFetchHoobiizUserGroup({groupId, noCache: true});
    showSuccess('Thème mis à jour');
  }, [whiteLabeling, groupId]);

  // Render
  const backLink = (
    <AdminNavLink to={getHoobiizUserGroupModalPath({hierarchy: groupIds})}>
      Retour au groupe
    </AdminNavLink>
  );
  if (!whiteLabeling) {
    return (
      <Wrapper>
        {backLink}
        <div>
          Chargement...
          <LoadingIndicator />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {backLink}
      <Title>{`Thème du groupe ${group?.item.groupLabel}`}</Title>
      <HomeLink
        target="_blank"
        href={
          IS_LOCALHOST_ENV
            ? `http://${group?.item.cseDomain}:24153`
            : `https://${group?.item.cseDomain}`
        }
      >
        {group?.item.cseDomain}
      </HomeLink>
      <FormSection title="Page de connexion">
        <AdminUserAndGroupThemeLoginConfig
          data={whiteLabeling.login}
          onChange={handleLoginChange}
        />
      </FormSection>
      <Button onClickAsync={handleSave}>Enregistrer</Button>
    </Wrapper>
  );
};

AdminUserAndGroupThemePage.displayName = 'AdminUserAndGroupThemePage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
`;

const HomeLink = styled(Link)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Title = styled.div`
  font-size: 24px;
`;
