import {FC, ReactNode, useCallback} from 'react';
import styled from 'styled-components';

import {
  CurrencyAmount,
  HoobiizCartItemId,
  HoobiizOrderItemItem,
  HoobiizOrderItemTicketInfo,
  HoobiizOrderStatus,
  HoobiizPrices,
} from '@shared/dynamo_model';
import {roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {baseHoobiizPrices} from '@shared/lib/hoobiiz/hoobiiz_prices';
import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {Link, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {SmallAlertButton} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {HoobiizOrderItemStatus} from '@src/components/ui/hoobiiz_order_item_status';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {HoobiizTicketLabel} from '@src/components/ui/hoobiiz_ticket_label';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface HoobiizTicketItemPropsBase {
  activity: SanitizedItem<'HoobiizActivity'>;
  quantity: number;
  total: HoobiizPrices;
  fees: CurrencyAmount;
  ticketInfo: HoobiizOrderItemTicketInfo;
  ticketInfoTotal: HoobiizPrices;
  options: HoobiizOrderItemItem['options'][0][];
  validityDateStr: string;
}

type HoobiizTicketItemExtraProps =
  | {
      cartInfo: {
        id: HoobiizCartItemId;
        onDeleteClick: (cartItemId: HoobiizCartItemId) => Promise<void>;
      };
    }
  | {
      orderInfo: {
        documentUrls: string[];
        status: HoobiizOrderStatus;
      };
    };

type HoobiizTicketItemProps = HoobiizTicketItemPropsBase & HoobiizTicketItemExtraProps;

export const HoobiizTicketItem: FC<HoobiizTicketItemProps> = props => {
  const {
    activity,
    quantity,
    total,
    ticketInfo,
    ticketInfoTotal,
    options,
    fees,
    validityDateStr,
    ...extra
  } = props;
  const ticketTitle = `${quantity > 1 ? `x${quantity} ` : ''}${ticketInfo.label}`;

  const handleDeleteClick = useCallback(async () => {
    if ('cartInfo' in extra) {
      const {id, onDeleteClick} = extra.cartInfo;
      await onDeleteClick(id);
    }
  }, [extra]);

  let statusElement = EmptyFragment;
  let documentUrlsElement: ReactNode = EmptyFragment;
  let deleteButtonElement = EmptyFragment;

  if ('cartInfo' in extra) {
    deleteButtonElement = (
      <SmallAlertButton onClickAsync={handleDeleteClick}>Supprimer</SmallAlertButton>
    );
  } else {
    const {orderInfo} = extra;
    const {documentUrls, status} = orderInfo;
    statusElement = <StyledHoobiizOrderItemStatus status={status} />;
    documentUrlsElement =
      documentUrls.length === 0 && status === HoobiizOrderStatus.Success ? (
        <DocumentLoading>Création de vos billets en cours...</DocumentLoading>
      ) : (
        documentUrls.map(url => (
          <DocumentLink key={url} href={url} target="_blank">
            <SvgIcon name="Download" color={Colors.DarkGold} size={16} /> Télécharger
          </DocumentLink>
        ))
      );
  }

  return (
    <ItemWrapper>
      {statusElement}
      <div>
        <ActivityTitleLink to={getActivityUrl(activity)}>{activity.label}</ActivityTitleLink>
        <ValidityDate>{validityDateStr}</ValidityDate>
      </div>
      <ItemInfo>
        <StyledHoobiizMediaView media={activity.media[0]} width={128} height={128} />
        <Right>
          <RightTop>
            <ItemTitle>
              <TicketTitle>
                <TicketTitleLink to={getActivityUrl(activity)}>
                  <HoobiizTicketLabel label={ticketTitle} description={ticketInfo.description} />
                </TicketTitleLink>
                <HoobiizPricesView prices={ticketInfoTotal} justify="flex-end" />
              </TicketTitle>
              {options.map(({quantity, total, option: {id, label, description}}) => {
                const optionTitle = ` ${quantity > 1 ? `x${quantity} ` : ''}${label}`;
                return (
                  <OptionTitle key={id}>
                    <HoobiizTicketLabel label={optionTitle} description={description} />
                    <HoobiizPricesView prices={total} justify="flex-end" />
                  </OptionTitle>
                );
              })}
            </ItemTitle>
            {roundCents(fees.cents) > 0 ? (
              <FeeTitle>
                <FeeTitleText>Frais de gestion</FeeTitleText>
                <HoobiizPricesView prices={baseHoobiizPrices(fees)} justify="flex-end" />
              </FeeTitle>
            ) : (
              EmptyFragment
            )}
            {roundCents(fees.cents) > 0 || options.length > 0 ? (
              <TotalTitle>
                <div>Total</div>
                <HoobiizPricesView prices={total} justify="flex-end" />
              </TotalTitle>
            ) : (
              EmptyFragment
            )}
          </RightTop>
          {deleteButtonElement}
          {documentUrlsElement}
        </Right>
      </ItemInfo>
    </ItemWrapper>
  );
};

HoobiizTicketItem.displayName = 'HoobiizTicketItem';

const StyledHoobiizMediaView = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  border: solid 1px #bcbcbc;
  padding: 16px 24px 8px 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityTitleLink = styled(UnthemedNavLink)`
  color: #333333;
  font-size: 24px;
  line-height: 150%;
  &:hover {
    text-decoration: underline;
  }
`;

const ValidityDate = styled.div`
  color: #555555;
  line-height: 150%;
`;

const BaseTitle = styled.div`
  color: #555555;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const TicketTitle = styled(BaseTitle)`
  font-size: 20px;
`;

const TicketTitleLink = styled(UnthemedNavLink)`
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
`;

const OptionTitle = styled(BaseTitle)`
  font-size: 18px;
`;

const FeeTitle = styled(BaseTitle)`
  font-size: 15px;
`;
const FeeTitleText = styled.div`
  font-style: italic;
`;

const TotalTitle = styled(BaseTitle)`
  color: #333333;
  font-size: 24px;
`;

const ItemInfo = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 22px;
`;

const Right = styled.div`
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DocumentLink = styled(Link)`
  gap: 4px;
`;

const DocumentLoading = styled.div`
  color: #999;
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
`;

const StyledHoobiizOrderItemStatus = styled(HoobiizOrderItemStatus)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-bottom-left-radius: 8px;
`;
