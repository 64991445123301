import {FC, Fragment} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat3Id} from '@shared/dynamo_model';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {getCat2Url} from '@src/lib/hoobiiz_urls';

interface Cat3PageProps {}

export const Cat3Page: FC<Cat3PageProps> = () => {
  const {cat3Id: cat3IdStr = ''} = useParams();
  const cat3Id = cat3IdStr as HoobiizCat3Id;

  const {data} = useApiCall(HoobiizApi, '/get-cat3', {cat3Id});

  return (
    <Wrapper>
      <HeaderWrapper>
        <BackNavLink to={data?.cat3?.cat2 ? getCat2Url(data.cat3.cat2) : '/'}>
          {data ? (
            <Fragment>
              <SvgIcon name="ChevronLeft" size={11} color={Colors.DarkGold} />
              {data.cat3?.cat2?.name ?? 'Retour'}
            </Fragment>
          ) : (
            EmptyFragment
          )}
        </BackNavLink>
        <Header>
          <HoobiizMediaView radius={16} media={data?.cat3?.media} width={135} height={100} />
          <Titles>
            <Title>{data?.cat3?.name ?? ''}</Title>
            <Description>{data?.cat3?.description ?? ''}</Description>
          </Titles>
        </Header>
      </HeaderWrapper>
      <HoobiizActivityModule catId={cat3Id} cse={{mode: 'all', showCheckbox: true}} />
    </Wrapper>
  );
};

Cat3Page.displayName = 'Cat3Page';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${Colors.DarkGrey};
`;

const BackNavLink = styled(NavLink)`
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  gap: 16px;
`;
const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
