import {FC, Fragment, useCallback} from 'react';

import {HoobiizWhiteLabelingLoginTheme1, HoobiizWhiteLabelingLoginType} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {Textarea} from '@shared-frontend/components/core/textarea_v2';

import {FormBlockFull, FormLabel} from '@src/components/admin/form/form_fragments';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {adminInputTheme} from '@src/components/core/theme';

interface AdminUserAndGroupThemeLoginTheme1ConfigProps {
  data?: HoobiizWhiteLabelingLoginTheme1;
  onChange: (data?: HoobiizWhiteLabelingLoginTheme1) => void;
}

export const AdminUserAndGroupThemeLoginTheme1Config: FC<
  AdminUserAndGroupThemeLoginTheme1ConfigProps
> = ({data, onChange}) => {
  const handleTitleChange = useCallback(
    (title?: string) => onChange({type: HoobiizWhiteLabelingLoginType.Theme1, ...data, title}),
    [onChange, data]
  );

  const handleHeroMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {hero, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({type: HoobiizWhiteLabelingLoginType.Theme1, ...rest, hero: status.id});
          return;
        }
      }
      onChange({type: HoobiizWhiteLabelingLoginType.Theme1, ...rest, hero: undefined});
    },
    [data, onChange]
  );

  const handleLogoMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {logo, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({type: HoobiizWhiteLabelingLoginType.Theme1, ...rest, logo: status.id});
          return;
        }
      }
      onChange({type: HoobiizWhiteLabelingLoginType.Theme1, ...rest, logo: undefined});
    },
    [data, onChange]
  );

  return (
    <Fragment>
      <FormBlockFull>
        <Textarea
          label="Titre"
          value={data?.title}
          syncState={handleTitleChange}
          overrides={adminInputTheme}
        />
      </FormBlockFull>
      <FormBlockFull>
        <FormLabel>LOGO</FormLabel>
        <MediaUpload
          initialMediaIds={removeUndefined([data?.logo])}
          onChange={handleLogoMediaChange}
        />
      </FormBlockFull>
      <FormBlockFull>
        <FormLabel>HERO</FormLabel>
        <MediaUpload
          initialMediaIds={removeUndefined([data?.hero])}
          onChange={handleHeroMediaChange}
        />
      </FormBlockFull>
    </Fragment>
  );
};

AdminUserAndGroupThemeLoginTheme1Config.displayName = 'AdminUserAndGroupThemeLoginTheme1Config';
