import {useEffect, useMemo} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizMediaId} from '@shared/dynamo_model';
import {uidUnsafe} from '@shared/lib/rand';
import {SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {createMapStore} from '@shared-frontend/lib/map_data_store';

const adminMediaStore = createMapStore<HoobiizMediaId, SanitizedItem<'HoobiizMedia'>>();
export const getAdminMedia = adminMediaStore.getData;
const setAdminMedia = adminMediaStore.setData;

const NO_MEDIA_ID = uidUnsafe() as HoobiizMediaId;

export function useHoobiizMedia(opts: {
  mediaId?: HoobiizMediaId;
}): SanitizedItem<'HoobiizMedia'> | undefined {
  const {mediaId} = opts;
  const current = adminMediaStore.useData(mediaId ?? NO_MEDIA_ID);

  useEffect(() => {
    if (mediaId && !current) {
      // Trigger a load if not available
      getOrFetchHoobiizMedia({mediaId}).catch(() => {});
    }
  }, [current, mediaId]);

  return current;
}

export function useHoobiizMedias(opts: {
  mediaIds: HoobiizMediaId[];
}): SanitizedItem<'HoobiizMedia'>[] | undefined {
  const {mediaIds} = opts;
  const current = adminMediaStore.useAllData();

  // On first call, trigger a load if not available
  useEffect(() => {
    const initialData = adminMediaStore.getAllData();
    for (const mediaId of mediaIds) {
      if (!initialData.has(mediaId)) {
        getOrFetchHoobiizMedia({mediaId}).catch(() => {});
      }
    }
  }, [mediaIds]);

  // Map all the mediaIds to their corresponding store item
  // If any mediaId is not available, return undefined
  const groups = useMemo(() => {
    const groups: SanitizedItem<'HoobiizMedia'>[] = [];
    for (const mediaId of mediaIds) {
      const group = current.get(mediaId);
      if (!group) {
        return undefined;
      }
    }
    return groups;
  }, [current, mediaIds]);

  return groups;
}

//

const FETCH_LOOP_TIMEOUT_MS = 5000;

const mediaFetchAwaiters = new Map<
  HoobiizMediaId,
  {resolve: (group: SanitizedItem<'HoobiizMedia'>) => void; reject: (err: unknown) => void}[]
>();
async function getOrFetchHoobiizMedia(opts: {
  mediaId: HoobiizMediaId;
}): Promise<SanitizedItem<'HoobiizMedia'>> {
  return new Promise<SanitizedItem<'HoobiizMedia'>>((resolve, reject) => {
    // Check if already in the store
    const {mediaId} = opts;
    const groupStoreItem = getAdminMedia(mediaId);
    if (groupStoreItem) {
      resolve(groupStoreItem);
      return;
    }

    // Not in the store, register as an awaiter
    let awaiters = mediaFetchAwaiters.get(mediaId);
    if (!awaiters) {
      awaiters = [];
      mediaFetchAwaiters.set(mediaId, awaiters);
    }
    awaiters.push({resolve, reject});
    if (awaiters.length > 1) {
      return; // someone is already fetching
    }

    // Fetch the group data
    apiCall(HoobiizApi, '/admin/get-media', {mediaId})
      .then(({item}) => {
        if (item) {
          setAdminMedia(mediaId, item);
          for (const {resolve} of mediaFetchAwaiters.get(mediaId) ?? []) {
            resolve(item);
          }
          mediaFetchAwaiters.set(mediaId, []);
        } else {
          setTimeout(() => {
            getOrFetchHoobiizMedia({mediaId}).catch(() => {});
          }, FETCH_LOOP_TIMEOUT_MS);
          for (const {reject} of mediaFetchAwaiters.get(mediaId) ?? []) {
            reject(new Error(`Media ${mediaId} not found`));
          }
          mediaFetchAwaiters.set(mediaId, []);
        }
      })
      .catch(err => {
        setTimeout(() => {
          getOrFetchHoobiizMedia({mediaId}).catch(() => {});
        }, FETCH_LOOP_TIMEOUT_MS);
        for (const {reject} of mediaFetchAwaiters.get(mediaId) ?? []) {
          reject(err);
        }
        mediaFetchAwaiters.set(mediaId, []);
      });
  });
}
