import {
  AddressLookupResult,
  HoobiizActivityAddress,
  HoobiizActivityAddressType,
  HoobiizVendorLocation,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';
import {DEFAULT_HOME_RANGE_IN_KM} from '@shared/model/google_maps_constants';

export function getActivityLocations(opts: {
  activityAddress: HoobiizActivityAddress;
  vendorLocations: HoobiizVendorLocation[];
}): Partial<HoobiizVendorLocation>[] {
  const {activityAddress, vendorLocations} = opts;

  if (activityAddress.type === HoobiizActivityAddressType.Vendor) {
    return vendorLocations;
  } else if (activityAddress.type === HoobiizActivityAddressType.AtHome) {
    const location = vendorLocations[activityAddress.vendorLocationIndex ?? 0];
    return location
      ? [{...location, rangeInKm: activityAddress.rangeInKm ?? DEFAULT_HOME_RANGE_IN_KM}]
      : [];
  } else if (activityAddress.type === HoobiizActivityAddressType.Website) {
    // A website doesn't have a physical location
    return [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (activityAddress.type === HoobiizActivityAddressType.Manual) {
    if (activityAddress.location) {
      return [activityAddress.location];
    }
    if (activityAddress.address) {
      return [
        {
          address: activityAddress.address,
          hideAddress: false,
          hideName: true,
          hidePhone: true,
          hideOpeningHours: true,
        },
      ];
    }
    return [];
  }

  // This should never happen
  return [];
}

export function addressToLines(address: AddressLookupResult): {line1: string; line2: string} {
  const {streetNumber, route, postalCode, city, country} = address.addressDetails;
  const chunk1 = removeUndefined([streetNumber, route]).join(' ');
  const chunk2 = removeUndefined([postalCode, city]).join(' ');
  const chunk3 = removeUndefined([country]).join(' ');
  return {line1: chunk1, line2: [chunk2, chunk3].filter(s => s.length > 0).join(', ')};
}
