import {FC, MouseEventHandler, useCallback} from 'react';
import styled from 'styled-components';

import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {showRawModal} from '@shared-frontend/components/core/modal';

import {WhiteButton} from '@src/components/core/theme';
import {HoobiizMediaGalleryModal} from '@src/components/ui/hoobiiz_media_gallery_modal';

interface HoobiizMediaGalleryViewProps {
  media: SanitizedItem<'HoobiizMedia'>[];
}

export const HoobiizMediaGalleryView: FC<HoobiizMediaGalleryViewProps> = props => {
  const {media} = props;
  const [img1, img2, img3] = media;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const hasMore = media.length > 3;

  const showMediasModal = useCallback(() => {
    showRawModal({
      children: <HoobiizMediaGalleryModal media={media} />,
      mode: 'fade-center',
      noCross: true,
      backdropColor: '#0c0c0cf7',
    });
  }, [media]);

  const handleMediaClick = useCallback<MouseEventHandler>(
    evt => {
      const index = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(index)) {
        return;
      }
      evt.stopPropagation();
      showRawModal({
        children: <HoobiizMediaGalleryModal initialIndex={index} media={media} />,
        mode: 'fade-center',
        noCross: true,
        backdropColor: '#0c0c0cf7',
      });
    },
    [media]
  );

  return (
    <Wrapper onClick={showMediasModal}>
      <Media1
        media={img1}
        onClick={handleMediaClick}
        data-index={0}
        size={{width: '100%', height: '100%'}}
      />
      <Media2
        media={img2}
        onClick={handleMediaClick}
        data-index={1}
        size={{width: '100%', height: '100%'}}
      />
      <Media3
        media={img3}
        onClick={handleMediaClick}
        data-index={2}
        size={{width: '100%', height: '100%'}}
      />
      {hasMore ? <SeeMediasButton>{`Voir les ${media.length} media`}</SeeMediasButton> : undefined}
    </Wrapper>
  );
};

HoobiizMediaGalleryView.displayName = 'HoobiizMediaGalleryView';

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 480px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

const Media1 = styled(HoobiizMediaView)`
  grid-column: 1/5;
  grid-row: 1/3;
`;
const Media2 = styled(HoobiizMediaView)`
  grid-column: 5/7;
  grid-row: 1;
`;
const Media3 = styled(HoobiizMediaView)`
  grid-column: 5/7;
  grid-row: 2;
`;

const SeeMediasButton = styled(WhiteButton)`
  position: absolute;
  top: 20px;
  left: 20px;
`;
