import {FC} from 'react';
import styled from 'styled-components';

import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';

interface HoobiizCat3ViewProps {
  name: string;
  media?: SanitizedItem<'HoobiizMedia'>;
  to: string;
}

export const HoobiizCat3View: FC<HoobiizCat3ViewProps> = props => {
  const {name, media, to} = props;

  return (
    <StyledNavLink to={to}>
      <Wrapper>
        <HoobiizMediaView media={media} width={135} height={100} />
        <Title>{name}</Title>
      </Wrapper>
    </StyledNavLink>
  );
};

HoobiizCat3View.displayName = 'HoobiizCat3View';

const StyledNavLink = styled(UnthemedNavLink)``;

const Wrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  img {
    transition: transform 200ms ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 16px;
  font-weight: 700;
  padding: 28px 8px 12px 8px;
  color: #ffffff;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
`;
