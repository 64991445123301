import {FC} from 'react';

import {HoobiizWhiteLabelingLogin} from '@shared/dynamo_model';

import {AdminUserAndGroupThemeLoginTheme1Config} from '@src/components/admin/user_and_group/admin_user_and_group_theme_login_theme1';

interface AdminUserAndGroupThemeLoginConfigProps {
  data?: HoobiizWhiteLabelingLogin;
  onChange: (data?: HoobiizWhiteLabelingLogin) => void;
}

export const AdminUserAndGroupThemeLoginConfig: FC<AdminUserAndGroupThemeLoginConfigProps> = ({
  data,
  onChange,
}) => {
  return <AdminUserAndGroupThemeLoginTheme1Config data={data} onChange={onChange} />;
};

AdminUserAndGroupThemeLoginConfig.displayName = 'AdminUserAndGroupThemeLoginConfig';
